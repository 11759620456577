import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { User } from '@app/shared/types/classes';
import { ITopControlOption } from '@app/types/interfaces/top-control-option.interface';

@Component({
  selector: 'app-top-controls',
  templateUrl: './top-controls.component.html',
  styleUrls: ['./top-controls.component.scss'],
})
export class TopControlsComponent implements OnInit {
  @Input() public user: User;
  @Input() public options: ITopControlOption[];
  @Output() public logout = new EventEmitter<void>();
  @Output() public startProductTour = new EventEmitter<void>();

  public ngOnInit(): void {}

  public showDropdown(element: HTMLElement): void {
    element.classList.add('is-active');
  }
  public hideDropdown(element: HTMLElement): void {
    element.classList.remove('is-active');
  }
}
