<div class="greeting__container">
  <div class="greeting__text">
    <h2 class="greeting__title" *ngIf="isDashboard">
      Hello <span (click)="handleNameClicked($event)">{{ user?.profile?.shareholders[0].firstName }}</span
      >, this is your Dashboard overview.
    </h2>
    <h2 class="greeting__title" *ngIf="isParticipantOverview">
      Hello {{ user?.profile?.shareholders[0].displayName }}.
    </h2>
    <div *ngIf="isVersionShown">
      FrontEnd Version: {{ frontEndVersion }} <span *ngIf="backEndVersion$ | async as backEndVersion"> | BackEnd Version: {{ backEndVersion.appVersion }} </span>
    </div>
    <p *ngIf="false" class="greeting__subtitle">
      You will need to fill in your revenue details for the month November. Start
      <span><a href="#" class="greeting__link">here</a> </span>
    </p>
  </div>
</div>
