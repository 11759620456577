import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { IBreadcrumb } from '@app/shared/types/interfaces';
import { User } from '@app/shared/types/classes';
import { PermissionsService } from '@app/shared/services/permissions.service';
import {
  PERMISSION_CONFIGURATION_FOR_MODULE_ACCESS,
  ModuleName,
} from '@app/shared/utils/constants/permission.constants';
import { ITopControlOption } from '@app/types/interfaces/top-control-option.interface';
import { ProductTourService } from '@app/services/product-tour.service';

@Component({
  selector: 'app-top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.scss'],
})
export class TopSectionComponent implements OnInit, OnChanges {
  @Input() public breadcrumbs: IBreadcrumb[];
  @Input() public user: User;
  @Input() public topControlOptions: ITopControlOption[];
  @Output() public logout = new EventEmitter<void>();
  public isDashboard: boolean;
  public hasCompanyModulePermission$: Observable<boolean>;
  public hasEsopParticipantModulePermission$: Observable<boolean>;

  constructor(
    private router: Router,
    private permissionService: PermissionsService,
    private productTourService: ProductTourService,
  ) {}

  public ngOnInit(): void {
    this.hasCompanyModulePermission$ = this.permissionService.isUserFitAnyAccessConfiguration$(
      PERMISSION_CONFIGURATION_FOR_MODULE_ACCESS[ModuleName.COMPANY],
    );
    this.hasEsopParticipantModulePermission$ = this.permissionService.isUserFitAnyAccessConfiguration$(
      PERMISSION_CONFIGURATION_FOR_MODULE_ACCESS[ModuleName.PARTICIPANT],
    );
  }

  public ngOnChanges(): void {
    this.isDashboard = this.router.url.includes('dashboard') || this.router.url.endsWith('/participant');
  }

  public handleLogout(): void {
    this.logout.emit();
  }

  public handleStartProductTour(): void {
    this.productTourService.startTour();
  }
}
