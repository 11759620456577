<div
  class="
    top-section
    flex-row
    is-align-items-center is-justify-content-space-between
    padding-right-l padding-top-l padding-left-l
  "
>
  <app-greeting [user]="user" *ngIf="isDashboard" class="greeting"></app-greeting>
  <app-breadcrumbs [breadcrumbs]="breadcrumbs" *ngIf="!isDashboard" [disabled]="true"></app-breadcrumbs>
  <ng-container>
    <ng-container *ngIf="hasCompanyModulePermission$ | async">
      <app-mode-selector *ngIf="hasEsopParticipantModulePermission$ | async"></app-mode-selector>
    </ng-container>
  </ng-container>
  <app-top-controls
    [user]="user"
    (logout)="handleLogout()"
    (startProductTour)="handleStartProductTour()"
    [options]="topControlOptions"
  ></app-top-controls>
</div>
